import React, { useState, useEffect } from 'react';

interface LoadingProps {
    text: string;
    loading: boolean;
}

const LoadingElipsis: React.FC<LoadingProps> = ({ text, loading }) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
            }, 500);

            return () => {
                clearInterval(interval);
            };
        } else {
            setDots(' ✔️');
        }
    }, [loading]);

    return (
        <div>
            <h4>
                {text}
                {dots}
            </h4>
        </div>
    );
};

export default LoadingElipsis;