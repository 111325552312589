export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    // { key: 'navigation', label: 'Navigation', isTitle: true },
    {
        key: 'dashboards',
        label: 'My Health',
        isTitle: false,
        icon: 'uil-home-alt',
        // badge: { variant: 'success', text: '4' },
        children: [
            {
                key: 'ds-analytics',
                label: 'Overview',
                url: '/myHealth/overview',
                parentKey: 'dashboards',
            },
            {
                key: 'ds-ecommerce',
                label: 'Detailed',
                url: '/myHealth/detailed',
                parentKey: 'dashboards',
            },
        ],
    },
    {
        key: 'apps-crm',
        label: 'RPM',
        isTitle: false,
        badge: { variant: 'danger', text: 'New' },
        icon: 'uil-tachometer-fast',
        children: [
            {
                key: 'crm-orders',
                label: 'Doctors',
                url: '/apps/crm/doctors',
                parentKey: 'apps-crm',
            },
            /*             {
                            key: 'crm-clients',
                            label: 'My Doctors',
                            url: '/apps/crm/clients',
                            parentKey: 'apps-crm',
                        }, */
        ],
    },
    {
        key: 'account',
        label: 'Account',
        isTitle: false,
        icon: 'uil-user',
        children: [
            {
                key: 'account-profile',
                label: 'Profile',
                url: '/pages/profile',
                parentKey: 'account',
            },
            {
                key: 'account-pricing',
                label: 'Plan 2',
                url: '/pages/pricing',
                parentKey: 'account',
            },
            {
                key: 'account-pricing',
                label: 'Plan 1',
                url: '/pages/pricing',
                parentKey: 'account',
            },
        ],
    },

];

export { MENU_ITEMS };
