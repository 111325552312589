import { activeConfig } from 'config/clientConfig';
import { APICore } from './apiCore';

const api = new APICore();

function login(params: { email: string; password: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/login/`;
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: {
    // user_name: string,
    name: string;
    last_name: string;
    phone: string;
    cellphone: string;
    gender_id: number;
    birth_date: string;
    emergency_name: string;
    emergency_cellphone: string;
    emergency_relationship: number;
    address: string;
    cpf: string;
    birth_address: string;
    blood_type: number;
    weight: number;
    height: number;
    email: string;
    password: string;
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/signup/`;
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/forget-password/`;
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

function resetPassword(params: { resetKey: string, password: string, confirmPassword: string }) {
    const baseUrl = '/reset-password/finish/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm, resetPassword };
