import { AuthActionTypes } from './constants';

export type AuthActionType = {
    type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;
    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    user_name: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (
    // user_name: string,
    name: string,
    last_name: string,
    phone: string,
    cellphone: string,
    gender_id: number,
    birth_date: string,
    emergency_name: string,
    emergency_cellphone: string,
    emergency_relationship: number,
    address: string,
    /*     address_place: string,
        address_number: string,
        address_building: string,
        address_neighborhood: string,
        address_city: string,
        address_state: string,
        address_zip: string, */
    cpf: string,
    birth_address: string,
    /*     birth_place: string,
        birth_city: string,
        birth_state: string,
        birth_country: string, */
    blood_type: number,
    weight: number,
    height: number,

    email: string,
    password: string): AuthActionType => ({
        type: AuthActionTypes.SIGNUP_USER,
        payload: {
            // user_name,
            name,
            last_name,
            phone,
            cellphone,
            gender_id,
            birth_date,
            emergency_name,
            emergency_cellphone,
            emergency_relationship,
            address,
            cpf,
            birth_address,
            blood_type,
            weight,
            height,
            email,
            password,
        },
    });

export const forgotPassword = (email: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});

export const resetPassword = (resetKey: string, password: string, confirmPassword: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
    payload: { resetKey, password, confirmPassword },
});

/*
    @email = '${userData.email}',
    @user_name = '${userData.user_name}',
    @password = '${userData.password}',
    @reference_id = '${userData.reference_id}',
    @name = '${userData.name}',
    @last_name = '${userData.last_name}',
    @birth_date = '${userData.birth_date}',
    @phone = '${userData.phone}',
        @birth_address = '${userData.birth_address}',
    @address = '${userData.address}',
    @gender_id = ${userData.gender_id},    
*/