import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    resetPassword as resetPasswordApi,

} from 'helpers';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';

type UserData = {
    payload: {
        name: string;
        last_name: string;
        phone: string;
        cellphone: string;
        gender_id: number;
        birth_date: string;
        emergency_name: string;
        emergency_cellphone: string;
        emergency_relationship: number;
        address: string;
        cpf: string;
        birth_address: string;
        blood_type: number;
        weight: number;
        height: number;
        email: string;
        password: string;

        // user_name: string;
        resetKey: string;
        confirmPassword: string;
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password }, type }: UserData): SagaIterator {
    try {
        const response = yield call(loginApi, { email, password });
        const user = response.data;

        api.setLoggedInUser(user);
        setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {
            ...user,
            isFirstTimeLogin: user.isFirstTimeLogin || false
        }));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: {
    // user_name,
    name,
    last_name,
    phone,
    cellphone,
    gender_id,
    birth_date,
    emergency_name,
    emergency_cellphone,
    emergency_relationship,
    address,
    cpf,
    birth_address,
    blood_type,
    weight,
    height,
    email,
    password, } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, {
            // user_name,
            name,
            last_name,
            phone,
            cellphone,
            gender_id,
            birth_date,
            emergency_name,
            emergency_cellphone,
            emergency_relationship,
            address,
            cpf,
            birth_address,
            blood_type,
            weight,
            height,
            email,
            password
        });
        //console.log('SignUp Response:', response.data[0].error_message);
        if (!response.data[0].success) {
            throw new Error(response.data[0].error_message);
        }
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { email });
        // console.log('Forgot Response:', response);

        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        // console.log('Forgot Error:', error);

        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* resetPassword({ payload: { resetKey, password, confirmPassword } }: UserData): SagaIterator {
    try {
        const response = yield call(resetPasswordApi, { resetKey, password, confirmPassword });
        //console.log('Forgot Response:', response);

        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error: any) {
        //console.log('Forgot Error:', error);

        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, resetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword), fork(watchResetPassword)]);
}

export default authSaga;
