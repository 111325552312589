import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const api = new APICore();
const BASE_URL = `/${activeConfig.api.DOMAIN}/`;

enum MeasurementType {
    Temperature = 'Temperature',
    Weight = 'Weight',
    OxygenSaturation = 'Oxygen saturation',
    Glucose = 'Glucose',
    Cholesterol = 'Cholesterol',
    UricAcid = 'Uric Acid',
    Lactate = 'Lactate',
    Triglycerides = 'Triglycerides',
    Ketone = 'Ketone',
    BloodPressure = 'Blood Pressure',
    HeartBeats = 'Heart Beats',
    Hemoglobin = 'Hemoglobin',
    Height = 'Height'
}

interface MeasurementParams {
    uuid_user: string;
    month: string;
    year: string;
    status_measurement?: string;
}

interface MeasurementPayload extends MeasurementParams {
    type_measurement: MeasurementType;
}

async function getMeasurement(params: MeasurementParams, type: MeasurementType) {
    const endpoint = `${BASE_URL}getMeasurement/`;
    const payload: MeasurementPayload = {
        ...params,
        type_measurement: type,
    };
    return api.create(endpoint, payload);
}

async function updateMeasurement(params: {
    uuid_user: string;
    measurementId: string;
    statusCurrent: string;
    comment: string;
}) {
    const endpoint = `${BASE_URL}UpdateMeasurement/`;
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,
    };
    return api.create(endpoint, payload);
}

async function getLastMeasurements(params: {
    uuid_user: string;
    month: string;
    year: string;
}) {
    const endpoint = `${BASE_URL}getLastMeasurements/`;
    return api.create(endpoint, params);
}

async function addMeasurement(params: {
    uuid_user: string;
    value: string;
    uuid_device: string;
    measurement_date: string;
    type_measurement: MeasurementType | string;
    uuid_edit: string;
}) {
    const endpoint = `${BASE_URL}addMeasurement/`;
    return api.create(endpoint, params);
}

// Create specific functions using the generic getMeasurement function
const getTemps = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Temperature);
const getWeights = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Weight);
const getOxygenSaturations = (params: MeasurementParams) => getMeasurement(params, MeasurementType.OxygenSaturation);
const getGlucose = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Glucose);
const getCholesterol = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Cholesterol);
const getUricAcid = (params: MeasurementParams) => getMeasurement(params, MeasurementType.UricAcid);
const getLactate = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Lactate);
const getTriglycerides = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Triglycerides);
const getKetone = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Ketone);
const getBloodPressure = (params: MeasurementParams) => getMeasurement(params, MeasurementType.BloodPressure);
const getHeartbeats = (params: MeasurementParams) => getMeasurement(params, MeasurementType.HeartBeats);
const getHemoglobins = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Hemoglobin);
const getHeights = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Height);

export {
    MeasurementType,
    getMeasurement,
    addMeasurement,
    getTemps,
    getWeights,
    getOxygenSaturations,
    getGlucose,
    getBloodPressure,
    getCholesterol,
    getUricAcid,
    getLactate,
    getTriglycerides,
    getKetone,
    getLastMeasurements,
    getHeartbeats,
    getHemoglobins,
    getHeights,
    updateMeasurement
};